import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate, useParams } from 'react-router-dom';
import { axiosInstance } from '../hooks/request.interceptor';
import { FormControlLabel, Switch, TextareaAutosize } from '@mui/material';
import { IMG_URL } from '../constants';
import { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';

export default function ManageSeries() {
    const [open, setOpen] = React.useState(true);

    const navigate = useNavigate();
    const [imageUrl, setImageUrl] = useState('');


    const handleClose = () => {
        setOpen(false);
        navigate(-1);
    };
    const { action, id } = useParams()
    const isEdit = action == "edit"

    React.useEffect(() => {
        if (isEdit) {
            axiosInstance.get('/series/admin/' + id).then(res => {
                let data = res.data.data
                setFormInput(data)
                setImageUrl(res.data.data.image);
            })
        }
    }, [])

    const [formInput, setFormInput] = React.useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            name: "",
            image: "",
            isPublished: false,
            price: 0,
            title: '',
            description: ''
        }
    );

    const handleSubmit = () => {
        let data = formInput;
        let path = isEdit ? 'update/' + id : 'create'
        axiosInstance.post('/series/admin/' + path, formInput).then(res => {
            navigate('/series')
        })

    }
    const setFormImageUrl = url => {
        let name = 'image'
        setFormInput({ [name]: url });
    }

    const handleInput = evt => {
        const name = evt.target.name;
        let newValue = evt.target.value;
        setFormInput({ [name]: newValue });
    };

    const handleInputCheck = evt => {
        const name = evt.target.name;
        let newValue = evt.target.checked;
        setFormInput({ [name]: newValue });
    };

    const deleteImage = () => {
        axiosInstance.delete(`/series/admin/uploadImage/SeriesImages/`, { data: imageUrl })
            .then(res => {
                if (res.data.status) {
                    setImageUrl('');
                }
            })
            .catch(err => {
                console.error("Error:", err);
            });
    }

    const uploadImage = (e) => {
        const file = e.target.files[0];

        if (!file) {
            console.error("No file selected");
            return;
        }

        const formData = new FormData();
        formData.append("image", file);
        axiosInstance.post(`/series/admin/uploadImage/SeriesImages/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(res => {
                if (res.data.status) {
                    const urlString = res.data.url;
                    const imagePath = urlString.substring(IMG_URL.length);
                    setFormImageUrl(imagePath);
                    setImageUrl(imagePath);
                }
            })
            .catch(err => {
                console.error("Error:", err);
            });
    }

    return (
        <React.Fragment>
            <Dialog open={open} fullWidth={true} maxWidth="sm">
                <DialogTitle style={{ 'padding-bottom': '0px' }}>{isEdit ? 'Edit Series' : 'Add Series'}</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="normal"
                        required
                        value={formInput.name}
                        fullWidth
                        name='name'
                        label="Series Name"
                        autoFocus
                        onChange={handleInput}
                    />
                    {imageUrl.length ?
                        <div className='test-icon-wrapper'>
                            <img alt="test" className='test-icon' src={IMG_URL + imageUrl} />
                            {imageUrl == "dummy.png" ? <></> : <DeleteIcon className='delete-image-icon' onClick={deleteImage} />}
                        </div>
                        : <div>
                            <input type="file" onChange={uploadImage} />
                        </div>
                    }
                    {/* <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="image"
                    label="Image"
                    value={formInput.image}
                    onChange={handleInput}
                /> */}
                    {isEdit && <FormControlLabel
                        label="isPublished"
                        control={
                            <Switch checked={formInput.isPublished} onChange={handleInputCheck} name="isPublished" />
                        }
                    />}
                    {/* <Switch label="isPublished" checked={formInput.isPublished} onChange={handleInputCheck} />} */}
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        type='number'
                        name="price"
                        label="Price"
                        value={formInput.price}
                        onChange={handleInput}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="title"
                        label="Title"
                        value={formInput.title}
                        onChange={handleInput}
                    />
                    <TextareaAutosize
                        margin="normal"
                        required
                        fullWidth
                        minRows={3}
                        style={{ padding: '15px 15px 15px 15px', 'marginTop': '10px', width: parseInt(`${isEdit ? 537 : 545}`) }}
                        name="description"
                        placeholder="Description"
                        value={formInput.description}
                        onChange={handleInput} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={() => { handleSubmit(); handleClose() }}>{isEdit ? 'Edit' : 'Create'}</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
