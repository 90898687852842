import {useState} from 'react';
import '../css/Navbar.css'
import DashboardIcon from '@mui/icons-material/Dashboard';
import SubjectIcon from '@mui/icons-material/Subject';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import CloudUploadRoundedIcon from '@mui/icons-material/CloudUploadRounded';
import { useAuth } from "../hooks/useAuth";
import {useNavigate} from 'react-router-dom';

const AppSideBar = () => {

    let sideBarContents = [
        {
            id : 1,
            link : "/dashboard",
            label: "Dashboard",
            icon: <DashboardIcon />,
        },
        {
            id : 2,
            link : "/series",
            label : "Series",
            icon: <SubjectIcon />,
        },
        {
            id: 3,
            link : "/upload",
            label: "Upload",
            icon: <CloudUploadRoundedIcon />,
            component : <><p>Upload component</p></>
        }
    ];

    const [selectedIndex,setSelectedIndex] = useState(1);
    const [buttonActive,setButtonActive] = useState(false);
    const { token, logout } = useAuth();
    const navigate = useNavigate();

    function redirect(path){
        navigate(path);
    }

    return (
        <>
            <div className='sidebar'>
                <div className='navbar-wrapper'>
                    <div className='logo'>SWOT</div>
                        <div className='menu-contents'>
                        {
                            sideBarContents.map((content,key)=>(
                            <div key={key} className='content-wrapper' onClick={()=> {setButtonActive(false);setSelectedIndex(content.id);redirect(content.link)}}>
                                <div className={`${selectedIndex === content.id ?'active':''} content`}>
                                    <div className='icon'>{content.icon}</div>
                                    <span className='label'>{content.label}</span>
                                </div>
                            </div>
                        ))
                    }
                    </div>
                    { !! token && (
                        <div className='content-wrapper'>
                            <div className={`${buttonActive ?'active':''} content`} onClick={()=> {setButtonActive(true);setSelectedIndex(0);logout()}}>
                                <span className='label'><LogoutRoundedIcon /></span>
                                <div className='icon'>Logout</div>
                            </div>
                        </div>
                    )
                    }
                </div>
            </div>
        </>
    )
}

export default AppSideBar;