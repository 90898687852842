import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button } from '@mui/material';
import '../css/Accordion.css';
import EditorPage from "../pages/EditorPage";
import { useState } from 'react';

const AccordionBar = ({ questionIndex, type, data, getQuestions, editType, addQuestion, setEditQuestion, setEditQuestionIndex, index }) => {

    const [open, setOpen] = useState(false);
    return (
        <div className="Accordion-wrapper">
            <Accordion style={{ margin: '25px 20px 25px 20px', 'boxShadow': 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography style={{ display: 'flex' }}>
                        <p>Q{questionIndex >= 0 ? `${questionIndex + 1}` : `${data?.order + 1}`}.&nbsp;</p><p dangerouslySetInnerHTML={{ __html: data.question }} />
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography style={{ margin: '5px' }}>
                        <p className='order'><strong>Options :</strong></p>
                        <div>
                            {
                                data?.options?.map((option, key) => (
                                    <p className='option' key={key}>
                                        {String.fromCharCode(65 + key)}. &nbsp;
                                        <div dangerouslySetInnerHTML={{ __html: option }} />
                                        {/* {option} */}
                                    </p>
                                ))
                            }
                        </div>
                        <p className='order'><strong>Answer :&nbsp;</strong>{String.fromCharCode(65 + parseInt(data?.answer))}</p>
                        <p className='explanation'><strong>Explanation :&nbsp;</strong><p dangerouslySetInnerHTML={{ __html: data.explanation }}></p></p>
                        <p className='order'><strong>Question Order :&nbsp;</strong>{questionIndex >= 0 ? `${questionIndex}` : `${data?.order}`}</p>
                        <p className='order'><strong>Topic :&nbsp;</strong> {data?.topic} </p>
                    </Typography>
                </AccordionDetails>
                <div className='edit-button'>
                    {
                        type === "create-question" ?
                            <Button style={{ 'backgroundColor': '#000000', 'color': '#ffffff' }} onClick={() => { setOpen(true); setEditQuestion(true); setEditQuestionIndex(index) }}>
                                Edit
                            </Button>
                            :
                            <Button style={{ 'backgroundColor': '#000000', 'color': '#ffffff' }} onClick={() => { setOpen(true); }}>
                                Edit
                            </Button>
                    }

                </div>
            </Accordion>
            {open ? <EditorPage addQuestion={addQuestion} getQuestions={getQuestions} type={editType ? 'create' : 'edit'} data={data} title={'Edit Question'} visiblity={open} method={setOpen} /> : <></>}
        </div>
    )
}

export default AccordionBar;