import React from 'react'
import { IMG_URL } from '../constants'
import { useNavigate } from 'react-router-dom'
import { axiosInstance } from '../hooks/request.interceptor';
import ConfirmDialog from './ConfirmDialog';
import { useState } from 'react';

const Test = ({ _id, name, isPublished, totalTiming, createdAt, updatedAt, image, reload }) => {
  const navigate = useNavigate();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const openTest = (_id) => {
    navigate('/questions/' + _id)
  }
  const editTest = (_id) => {
    navigate('/test/edit/' + _id)

  }
  const deleteTest = (_id) => {
    axiosInstance.post('/tests/admin/delete/' + _id).then(res => {
      reload();
      console.log("response", res, _id);
    })
  }

  const testCreatedAt = new Date(createdAt).toLocaleString(
    "en-US",
      {
        month: "short",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      }
  );

  const testUpdatedAt =  new Date(updatedAt).toLocaleString(
    "en-US",
      {
        month: "short",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      }
  );

  return (
    <div className='card-series-box' style={{ 'border': isPublished ? '1px solid green' : '1px solid red' }}>

      <div className='image-wrapper'>
        <img className='image' alt="test" src={IMG_URL + image} onClick={() => { openTest(_id) }} />
      </div>

      <div className='card-content-wrapper'>

        <div className='series-name-wrapper'>
          <p className='name'>{name}</p>
        </div>

        <div className='total-tests-wrapper'>
          <p>Total Time : {totalTiming}</p>
        </div>

        <div className='time-stamp'>
          <p>Created At : {testCreatedAt}</p>
        </div>

        <div className='time-stamp'>
          <p>Updated At : {testUpdatedAt}</p>
        </div>

        <div className='view-button-wrapper'>
          <button className='view-button custom-btn btn-6' onClick={() => { openTest(_id) }}>
            VIEW
          </button>
        </div>

        <div className='buttons'>
          <button className='custom-btn btn-6' onClick={() => { editTest(_id) }}>EDIT</button>
          <button className='custom-btn btn-6'
            onClick={() => {
              setConfirmOpen(true);
            }
            }
          >DELETE</button>
          <ConfirmDialog
            title="Delete Test?"
            open={confirmOpen}
            setOpen={setConfirmOpen}
            onConfirm={() => { deleteTest(_id) }}
          >
            Are you sure you want to delete this Test?
          </ConfirmDialog>
        </div>
      </div>
    </div>
  )
}

export default Test