import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { axiosInstance } from '../hooks/request.interceptor'
import { useLoading } from '../hooks/useLoading';
import Loader from '../components/Loader';
import '../css/Questions.css'
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Questions from '../components/Questions'
import ConfirmDialog from '../components/ConfirmDialog'

function QuestionsPage() {
  const { id } = useParams()
  const [data, setData] = useState([]);
  const [modifiedData, setModifiedData] = useState([]);
  const { loading, setLoading } = useLoading();
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [confirmOpen, setConfirmOpen] = useState(false);
  const getQuestions = () => {
    setLoading(true);
    axiosInstance.get('/tests/admin/test/' + id).then(res => {
      console.log("questions", res.data);
      setLoading(false);
      setData(res.data)
      setModifiedData(res.data);
    })
  }

  useEffect(() => {
    setLoading(true);
    getQuestions();
  }, []);

  const totalSteps = () => {
    return modifiedData.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? data.findIndex((step, i) => !(i in completed)) : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const update = () => {
    // const updatedSections = modifiedData?.sections.map(section => {
    const selectedSection = modifiedData?.sections[activeStep];
    const updatedQuestions = selectedSection?.questions.map((question, index) => {
      const { _id } = question;
      return { order: index, _id: _id };
    });
    // return {updatedQuestions};
    // return [...updatedQuestions];
    // });
    console.log("Updated sections",updatedQuestions);
    // const flattedArray = updatedSections.flat(1);
    axiosInstance.post('tests/admin/updateQuestions', updatedQuestions).then(
      res => {
        if (res.data.status) {
          // console.log("Res for updating questions order", res.data);
          getQuestions();
        }
      }
    );
  }

  const reset = () => {
    setLoading(true);
    getQuestions();
  }

  return (
    (loading ? <Loader /> :
      <div className='questions-wrapper'>
        <div className='test-name-wrapper'>
          <h2>{modifiedData.name}</h2>
        </div>
        <Box sx={{ width: '100%' }}>
          <Stepper style={{ margin: '40px 45px 25px 25px' }} nonLinear activeStep={activeStep}>
            {modifiedData?.sections?.map((label, index) => (
              <Step key={label.name} completed={completed[index]}>
                <StepButton color="inherit" onClick={handleStep(index)}>
                  <p className='stepper-name' >{label.name}</p>
                </StepButton>
              </Step>
            ))}
          </Stepper>
          <div>
            {allStepsCompleted() ? (
              <>
                <Button onClick={handleReset}>Reset</Button>
              </>
            ) : (
              <Questions getQuestions={getQuestions} data={modifiedData} setModifiedData={setModifiedData} activeStep={activeStep} />
            )}
          </div>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', pt: 2 }}>
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}>
            Back
          </Button>
          <Button sx={{ backgroundColor: 'black', color: 'white', "&:hover": { backgroundColor: "black" } }} onClick={() => { setConfirmOpen(true) }}>Update</Button>
          <Button sx={{ backgroundColor: 'black', color: 'white', "&:hover": { backgroundColor: "black" } }} onClick={reset}>Reset</Button>
          <Button onClick={handleNext} sx={{ mr: 1 }}>Next</Button>
        </Box>

        <ConfirmDialog
          title="update questions order?"
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={update}
        >
          <p style={{ 'fontFamily': 'sans-serif' }}>Are you sure you want to update the Current order of the Questions?</p>
        </ConfirmDialog>
      </div>
    )
  )
}

export default QuestionsPage