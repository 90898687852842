import { Delete, Edit } from '@mui/icons-material';
import { Box, DialogActions, DialogContent, DialogTitle, Button, Container, Dialog, Switch, TextField } from '@mui/material'
import React, { useEffect, useReducer, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ConfirmDialog from '../components/ConfirmDialog'
import { useLoading } from '../hooks/useLoading'
import { axiosInstance } from '../hooks/request.interceptor';
import AccordianBar from '../components/Accordion';
import '../css/test.css'
import EditorPage from './EditorPage';
import DeleteIcon from '@mui/icons-material/Delete';
import Loader from '../components/Loader';
import { IMG_URL } from '../constants';

const Section = ({ name, code, questions_count, index, editSection, deleteSection, setSelectedSection }) => {
    return (
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box>{name}</Box>
            <Box>{code}</Box>
            <Box>{questions_count}</Box>
            <Box>
                <Button onClick={() => { editSection(index); setSelectedSection(index); }}><Edit /></Button>
                <Button onClick={() => { deleteSection(index) }}><Delete /></Button>
            </Box>
        </Box>
    )
}

const ManageTest = () => {
    const { loading, setLoading } = useLoading();
    const [selectedSection, setSelectedSection] = useState(null);
    const navigate = useNavigate();
    const [open, setOpen] = useState(true);
    const [questions, setQuestionLimitDialog] = useState(false);

    const [newSection, setNewSection] = useState(false);
    const [sectionContents, setSectionContents] = useState([]);
    const [newSectionQuestions, setNewSectionQuestions] = useState([]);
    const [editQuestion, setEditQuestion] = useState(false);
    const [sectionEditingIndex, setSectionEditingIndex] = useState(null);
    const [editQuestionIndex, setEditQuestionIndex] = useState(null);
    const [imageUrl, setImageUrl] = useState('');

    const { id, action } = useParams();
    const [sections, setSections] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isSectionEditing, setIsSectionEditing] = useState(false);
    const [sectionDeletingIndex, setSectionDeletingIndex] = useState(null)
    const [questionDialog, setQuestionDialog] = useState(false);
    const [question, setQuestion] = useState([]);

    const openDialog = () => {
        setQuestionDialog(true);
    };
    // const closeDialog = () => {
    //     setQuestionDialog(false);
    // };

    const [confirmOpen, setConfirmOpen] = useState(false)
    const isEdit = action === "edit"

    const handleInput = evt => {
        const name = evt.target.name;
        let newValue = evt.target.value;
        setFormInput({ [name]: newValue });
    };
    const setFormImageUrl = url => {
        let name = 'image'
        setFormInput({ [name]: url });
    }
    const handleInputCheck = evt => {
        const name = evt.target.name;
        let newValue = evt.target.checked;
        setFormInput({ [name]: newValue });
    };
    const handleSectionInputCheck = evt => {
        const name = evt.target.name;
        let newValue = evt.target.checked;
        setSectionInput({ [name]: newValue });
    };
    const [formInput, setFormInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            name: "",
            image: "",
            totalTiming: "",
            // isPublished: false,
            sectionLock: true,
            // code: "ASASA_ASA"
        }
    );
    const [sectionInput, setSectionInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            name: "",
            code: "",
            totalTiming: "",
            questions_count: "",
            sectionLock: true,
            timing: 0,
            questions: []
        }
    );

    useEffect(() => {
        if (action === "edit") {
            axiosInstance.get('/tests/admin/test/' + id).then(res => {
                console.log("edit test", res.data);
                setFormInput(res.data)
                setSections(res.data.sections)
                setLoading(false);
                setImageUrl(res.data.image);
            })
        }
    }, [])

    // useEffect(() => {
    //     console.log("question",question);
    // }, [question]);

    const handleSectionInput = evt => {
        const name = evt.target.name;
        let newValue = evt.target.value;
        setSectionInput({ [name]: newValue });
    };

    const handleSubmit = () => {
        let path = isEdit ? 'update/' + id : 'create'

        if (isEdit) {
            axiosInstance.post('/tests/admin/' + path, formInput).then(res => {
                navigate(-1);
            })
        } else {
            setLoading(true);
            const sectionsClone = [...sections];

            if (sectionsClone?.length) {
                sectionsClone.map((section) => {
                    section?.questions?.map((question, i) => {
                        return { ...question, order: i };
                    })
                    return { ...section };
                })
            }

            const clientData = {
                ...formInput,
                seriesId: id,
                sections: sectionsClone
            }
            console.log("client data", clientData);

            axiosInstance.post('/tests/admin/' + path, clientData).then(res => {
                setLoading(false);
                navigate(-1);
            })

        }

    }

    const deleteQuestion = (index) => {
        if (newSectionQuestions.length) {
            const updatedSectionQuestions = [...newSectionQuestions];
            updatedSectionQuestions.splice(index, 1);
            setNewSectionQuestions(updatedSectionQuestions);
        } else {
            let tempSections = [...sections];
            tempSections[selectedSection].questions.splice(index, 1);
            // setSectionContents(tempSections);
            setSections(tempSections);
        }
    }

    const addQuestion = (newQuestion) => {
        // console.log("----->adding question new section",newSection,"editing",isSectionEditing,"edit question",editQuestion);
        if (newSection) {
            const previousNewSectionQuestions = newSectionQuestions;
            previousNewSectionQuestions.push(newQuestion);
            setNewSectionQuestions(previousNewSectionQuestions);
            setEditQuestion(false);
        }

        if (isSectionEditing) {
            if (editQuestion) {
                let tempSections = [...sections];
                if (tempSections[selectedSection] && tempSections[selectedSection].questions) {
                    tempSections[selectedSection].questions[editQuestionIndex] = newQuestion;
                    setSectionContents(tempSections);
                }
            } else {
                let tempSections = sections;
                let prevQuestions = tempSections[selectedSection]?.questions || [];
                prevQuestions.push(newQuestion);
                tempSections[selectedSection] = { ...sectionInput, questions: prevQuestions };
                setSectionContents(tempSections);
                setQuestion([]);
            }
            // setNewSection(false);
            setEditQuestion(false);
            setEditQuestionIndex(null);
        }
    }

    const handleSectionSumbit = (e) => {
        e.preventDefault()

        // console.log("New Section",newSection);
        // console.log("Edit Section",isSectionEditing);

        if (isSectionEditing) {
            let tempSections = [...sections]
            tempSections[sectionEditingIndex] = sectionInput

            if (sectionInput.questions_count >= 0 && (sectionInput.questions_count == tempSections[sectionEditingIndex]?.questions?.length)) {
                // console.log("Equal number of questions when editing a section");
                setSections(tempSections);
                setSectionInput({
                    name: "",
                    code: "",
                    questions_count: "",
                    totalTiming: 0
                })
                setSectionEditingIndex(null);
                setIsModalOpen(false)
                setQuestion([]);
                setIsSectionEditing(false);
                setNewSection(false);
                return;
            } else {
                setQuestionLimitDialog(true);
                // console.log("Questions were unequal during editing");
            }
        }

        if (newSection) {
            let tempSections = [...sections];

            if (sectionInput.questions_count >= 0 && (sectionInput.questions_count == newSectionQuestions?.length)) {
                // console.log("Equal number of questions when adding an new section");
                tempSections.push({ ...sectionInput, questions: newSectionQuestions })
                setSections(tempSections);
                setNewSection(false);
                setIsSectionEditing(false);
                setIsModalOpen(false);
                setNewSectionQuestions([]);
                setSectionInput({
                    name: "",
                    code: "",
                    questions_count: "",
                    totalTiming: 0
                })
                setQuestion([]);
            } else {
                setQuestionLimitDialog(true);
                // console.log("Questions were unequal during addition of a new section");
            }

        }

    }
    const editSection = (i) => {
        setIsSectionEditing(true)
        setSectionInput(sections[i])
        setIsModalOpen(true);
        setSectionEditingIndex(i);
    }
    const deleteSection = (i) => {
        setConfirmOpen(true)
        setSectionDeletingIndex(i);
    }

    const deleteSectionSelect = (i) => {
        let tempSections = [...sections]
        tempSections.splice(sectionDeletingIndex, 1)
        setSections(tempSections);
        setConfirmOpen(false)
    }

    const cancelAddingSection = () => {
        setNewSectionQuestions([]);
        setIsModalOpen(false);
        setNewSection(false);
        setIsSectionEditing(false);
        setQuestion([]);
        setSectionInput({
            name: "",
            code: "",
            questions_count: "",
            totalTiming: 0
        })
    }

    const addSection = () => {
        setIsModalOpen(true);
        setSelectedSection(null);
        setNewSection(true);
        setQuestion([]);
        setSectionInput({
            name: "",
            code: "",
            questions_count: "",
            totalTiming: 0
        })
    }

    const handleClose = () => {
        setOpen(false);
        navigate(-1);
    };

    const deleteImage = () => {
        axiosInstance.delete(`/series/admin/uploadImage/SeriesImages/`,{data:imageUrl})
            .then(res => {
                if (res.data.status) {
                    setImageUrl('');                  
                }
            })
            .catch(err => {
                console.error("Error:", err);
            });
    }

    const uploadImage = (e) => {
        const file = e.target.files[0];

        if (!file) {
            console.error("No file selected");
            return;
        }

        const formData = new FormData();
        formData.append("image", file);
        setLoading(true);
        axiosInstance.post(`/series/admin/uploadImage/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(res => {
                if (res.data.status) {
                    const urlString = res.data.url;
                    const imagePath = urlString.substring(IMG_URL.length);
                    setFormImageUrl(imagePath);
                    setImageUrl(imagePath);
                    setLoading(false);
                }
            })
            .catch(err => {
                console.error("Error:", err);
            });
    }


    return (
        // (loading ? <Loader /> :
            <React.Fragment>
                <Dialog open={open} fullWidth={true} maxWidth="sm">
                    <DialogTitle style={{ 'paddingBottom': '0px' }}>{isEdit ? 'Edit Test' : 'Add Test'}</DialogTitle>
                    <DialogContent>
                        <TextField
                            margin="normal"
                            required
                            value={formInput.name}
                            fullWidth
                            name='name'
                            label="Test Name"
                            autoFocus
                            onChange={handleInput}
                        />
                        {imageUrl.length ?
                            <div className='test-icon-wrapper'>
                                <img alt="test" className='test-icon' src={IMG_URL + imageUrl} />
                                {imageUrl == "dummy.png" ? <></> : <DeleteIcon className='delete-image-icon' onClick={deleteImage} /> }
                            </div>
                            : <div>
                                <input type="file" onChange={uploadImage} />
                            </div>
                        }
                        {/* <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="image"
                            label="Image"
                            value={formInput.image}
                            onChange={handleInput}
                        /> */}
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="totalTiming"
                            label="Total Timing"
                            value={formInput.totalTiming}
                            onChange={handleInput}
                        />
                        Section Lock: <Switch name="sectionLock" checked={formInput.sectionLock} onChange={handleInputCheck} />
                        {isEdit && "Publish:"}
                        {isEdit && <Switch name="isPublished" checked={formInput.isPublished} onChange={handleInputCheck} />}

                        <Box>
                            {sections.map((e, i) => <Section {...e} key={i} {...{ setSelectedSection, deleteSection, editSection }} index={i} />)}
                            <Button variant='contained' onClick={() => { addSection() }} >Add Section</Button>
                        </Box>
                        <Dialog fullScreen open={isModalOpen} onClose={() => { setIsModalOpen(false) }} >
                            <Container>
                                <Box component="form" onSubmit={handleSectionSumbit}>
                                    <TextField
                                        margin="normal"
                                        required
                                        value={sectionInput.name}
                                        fullWidth
                                        name='name'
                                        label="Section Name"
                                        autoFocus
                                        onChange={handleSectionInput}
                                    />
                                    <TextField
                                        margin="normal"
                                        required
                                        value={sectionInput.code}
                                        fullWidth
                                        name='code'
                                        label="Section Code"
                                        inputProps={{ pattern: "[A-Z_]+" }}
                                        autoFocus
                                        onChange={handleSectionInput}
                                    />
                                    <TextField
                                        margin="normal"
                                        required
                                        value={sectionInput.questions_count}
                                        fullWidth
                                        name='questions_count'
                                        label="Questions Count"
                                        inputProps={{ pattern: "[0-9]+" }}
                                        autoFocus
                                        onChange={handleSectionInput}
                                    />
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="totalTiming"
                                        label="Total Timing"
                                        value={sectionInput.totalTiming}
                                        onChange={handleSectionInput}
                                    />
                                    Section Lock: <Switch name="sectionLock" checked={sectionInput.sectionLock} onChange={handleSectionInputCheck} />
                                    <Button variant='contained' onClick={openDialog}>Add Question</Button>
                                    <div className='test-section-wrapper'>
                                        <Button variant="contained" onClick={() => { cancelAddingSection() }}>Cancel</Button>
                                        <Button type="submit" variant="contained">Add</Button>
                                        <Dialog open={questions} onClose={() => { setQuestionLimitDialog(false) }}>
                                            <DialogContent sx={{ fontFamily: "sans-serif", fontSize: '15px', fontWeight: 900 }} >Please enter the number of questions as per the question count.</DialogContent>
                                        </Dialog>
                                    </div>
                                    {
                                        questionDialog ? <EditorPage addQuestion={addQuestion} type={'create'} title={'Add Question'} visiblity={questionDialog} method={setQuestionDialog} data={question} /> : <></>
                                    }
                                    <div>
                                        {
                                            newSection ? (
                                                newSectionQuestions?.length ?
                                                    <>
                                                        {newSectionQuestions.map((content, index) => (
                                                            <div key={index} className='options'>
                                                                <AccordianBar questionIndex={index} addQuestion={addQuestion} editType={'create'} style={{ width: '100%' }} data={content} />
                                                                <DeleteIcon className='delete-button' onClick={() => deleteQuestion(index)} />
                                                            </div>

                                                        ))}
                                                    </>
                                                    : <div>
                                                        <p>No Questions Available</p>
                                                    </div>

                                            ) :
                                                sections[selectedSection]?.questions ? (
                                                    sections[selectedSection].questions.map((content, index) => (
                                                        <div key={index} className='options'>
                                                            <AccordianBar questionIndex={index} type={'create-question'} setEditQuestionIndex={setEditQuestionIndex} index={index} setEditQuestion={setEditQuestion} addQuestion={addQuestion} editType={'create'} style={{ width: '100%' }} data={content} />
                                                            <DeleteIcon className='delete-button' onClick={() => deleteQuestion(index)} />
                                                        </div>

                                                    ))
                                                ) :
                                                    <></>
                                        }
                                    </div>
                                </Box>
                            </Container>
                        </Dialog>
                    </DialogContent>
                    <DialogActions>
                        <Button variant='contained' style={{ color: 'white' }} onClick={handleClose}>Cancel</Button>
                        <Button variant='contained' style={{ color: 'white' }} onClick={() => { handleSubmit(); }}>{isEdit ? 'Edit' : 'Create'}</Button>
                    </DialogActions>
                    <ConfirmDialog
                        title="Delete Test?"
                        open={confirmOpen}
                        setOpen={setConfirmOpen}
                        onConfirm={deleteSectionSelect}
                    >
                        Are you sure you want to delete this Test?
                    </ConfirmDialog>
                </Dialog >
            </React.Fragment >
        // )
    )
}

export default ManageTest