import { Typography } from "@mui/material";
import AccordionBar from "./Accordion";
import { useRef } from "react";

const Questions = ({ data, setModifiedData, activeStep, getQuestions }) => {
  const dragSlides = useRef(0);
  const draggedOverSlides = useRef(0);

  function handleSort() {
    const quesClone = [...data?.sections[activeStep]?.questions];
    const temp = quesClone[dragSlides.current];
    quesClone[dragSlides.current] = quesClone[draggedOverSlides.current];
    quesClone[draggedOverSlides.current] = temp;

    let all_data = data?.sections;
    let newData;
    if (all_data && all_data[activeStep]) {
      console.log("all", all_data[activeStep].questions)
      all_data[activeStep].questions = quesClone;
      newData = { ...data, sections: all_data };
      setModifiedData(newData);
    }
  }

  return (
    <>
      <Typography sx={{ mt: 2, mb: 1, py: 1, mx: 1 }}>
        {
          data?.sections[activeStep]?.questions?.map((item, index) => (
            <div draggable
              key={index}
              onDragStart={() => (dragSlides.current = index)}
              onDragEnter={() => (draggedOverSlides.current = index)}
              onDragEnd={handleSort}
              onDragOver={(e) => e.preventDefault()} >
              <AccordionBar getQuestions={getQuestions} data={item} />
            </div>
          ))
        }
      </Typography>
    </>
  )
}

export default Questions;