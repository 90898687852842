import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import axios from "axios";

const AuthContext = createContext();

export const AuthProvider = ({ children, tokenData }) => {
  const [token, setToken] = useLocalStorage("token", tokenData);
  const navigate = useNavigate();

  const login = async (data) => {
    await axios
      .post("https://api.swotacademy.co.in/api/auth/admin/login", data)
      .then((res) => {
        setToken(res.data.access_token);
        navigate("/dashboard", { replace: true });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const logout = () => {
    setToken(null);
    window.localStorage.removeItem('seriesId');
    navigate("/", { replace: true });
  };

  const value = useMemo(
    () => ({
      token,
      login,
      logout,
    }),
    [token]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
