import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Card, DialogActions, DialogContent, DialogTitle, List, TextField } from '@mui/material';
import QuillEditor from '../components/Editor'
import { useState } from 'react';
import '../css/EditorPage.css'
import EditorPreview from '../components/Preview';
import DeleteIcon from '@mui/icons-material/Delete';
import { axiosInstance } from '../hooks/request.interceptor';
import { useLoading } from '../hooks/useLoading';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditorPage({getQuestions, type, addQuestion, title, visiblity, method, data }) {
  const { loading, setLoading } = useLoading();

  const [editorData, setData] = useState(
    {
      order: data?.order || 0,
      question: data?.question || "",
      explanation: data?.explanation || "",
      answer: data?.answer || 0,
      options: data?.options || [],
      topic: data?.topic || "",
      section: data?.section || ""
    }
  );

  const [open, setOpen] = useState(false);
  const [option, newOption] = useState('');

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const handleClose = () => {
    method(false);
  };

  const handleAnswer = (e) => {
    const inputLetter = e.target.value.toUpperCase(); // Converting to uppercase for consistency
    const letterCode = inputLetter.charCodeAt(0) - 'A'.charCodeAt(0);

    if (/^[A-Z]$/.test(inputLetter)) {
      editorDataChange('answer', letterCode);
    }
  }

  const editorDataChange = (key, value) => {
    setData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const deleteOption = (index) => {
    const updatedOptions = [...editorData.options];
    updatedOptions.splice(index, 1);
    editorDataChange('options', updatedOptions);
  }

  const editOption = (index, value) => {
    setData((prevData) => {
      const updatedOptions = [...prevData.options];
      updatedOptions[index] = value;
      return { ...prevData, options: updatedOptions };
    });
  };

  const handleSubmit = () => {
    const updatedOptions = [...editorData.options, option];
    editorDataChange('options', updatedOptions);
    closeDialog();
  };

  const handleSave = () => {
    if (type === 'edit') {
      setLoading(true);
      console.log("editorData",editorData);
      axiosInstance.post('/tests/admin/updateQuestion/'+data?._id,editorData).then(res => {
        if(res.data.status){
          console.log("res",res.data);
          setLoading(false);
          getQuestions();
        }else {
          console.log("failed to upload");
        }
      })
    } else if (type === 'create') {
      addQuestion(editorData);
    }
  }

  return (
    <>
      <Dialog
        fullScreen
        open={visiblity}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon onClick={handleClose} />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {title}
            </Typography>
            <Button autoFocus color="inherit" onClick={() => { handleClose(); handleSave() }}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <div style={{ display: 'flex', overflow: 'hidden' }}>
          <List sx={{
            width: '50%', height: '100%', 'fontFamily': 'sans-serif', overflow: 'scroll', '&::-webkit-scrollbar': {
              display: 'none',
            },
            padding: '10px'
          }}>
            <div className='contents'>
              <p className='heading-wrapper'>Question</p>
              <QuillEditor defaultvalue={editorData.question}
                modifyFunction={(value) => editorDataChange('question', value)}
              />
            </div>
            <div className='contents'>
              <p className='heading-wrapper'>Explanation</p>
              <QuillEditor defaultvalue={editorData.explanation}
                modifyFunction={(value) => editorDataChange('explanation', value)}
              />
            </div>
            <div className='contents'>
              <p className='heading-wrapper'>Answer</p>
              <TextField defaultValue={String.fromCharCode(65 + editorData.answer)} onChange={(e) => handleAnswer(e)} />
            </div>
            <div className='contents'>
              <p className='heading-wrapper'>Options</p>
              {
                editorData.options?.map((option, index) => (
                  <div className='options' key={index}>
                    <p>
                      {String.fromCharCode(65 + index)}&nbsp;
                    </p>
                    <QuillEditor defaultvalue={option}
                      modifyFunction={(data) => editOption(index, data)}
                    />
                    {/* <TextField defaultValue={option} onChange={(e) => editOption(index, e.target.value)} sx={{ width: '100%', margin: '5px' }} /> */}
                    <DeleteIcon className='delete-button' onClick={() => deleteOption(index)} />
                  </div>
                ))
              }
            </div>
            <Button onClick={openDialog} sx={{ margin: '5px', backgroundColor: 'black', color: 'white', "&:hover": { backgroundColor: "black" } }} >
              Add Option
            </Button>
            {
              open ? <>
                <Dialog
                  fullWidth
                  open={open}
                  onClose={closeDialog}
                >
                  <DialogTitle>Add Option</DialogTitle>
                  {/* <form onSubmit={handleSubmit}> */}
                  <DialogContent>
                    <TextField
                      autoFocus
                      required
                      margin="dense"
                      id="name"
                      label="option"
                      fullWidth
                      variant="standard"
                      onChange={(e) => newOption(e.target.value)}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={closeDialog}>Cancel</Button>
                    <Button onClick={handleSubmit} type="submit">Add</Button>
                  </DialogActions>
                  {/* </form> */}
                </Dialog>
              </> : <></>
            }

            {/* <div className='contents'>
              <p className='heading-wrapper'>No of Options</p>
              <TextField defaultValue={options.length} modifyFunction={setExplanation} />
            </div> */}
            <div className='contents'>
              <p className='heading-wrapper'>Section</p>
              <TextField defaultValue={editorData.section} onChange={(e) => editorDataChange('section', e.target.value)} />
            </div>
            <div className='contents'>
              <p className='heading-wrapper'>Topic</p>
              <TextField defaultValue={editorData.topic} onChange={(e) => editorDataChange('topic', e.target.value)} />
            </div>

          </List>
          <Card sx={{
            width: '50%', overflow: 'scroll', '&::-webkit-scrollbar': {
              display: 'none'
            }
          }}>
            <EditorPreview question={editorData.question} answer={editorData.answer} options={editorData.options} explanation={editorData.explanation} topic={editorData.topic} section={editorData.section} />
          </Card>
        </div>
      </Dialog>
    </>
  );
}
