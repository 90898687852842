// import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { ThemeProvider } from "@mui/material/styles";
import { RouterProvider } from "react-router-dom";

import { router } from "./App";
import theme from "./context/themeContext";
import { LoadingProvider } from "./hooks/useLoading";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  // <StrictMode>
  <ThemeProvider theme={theme}>
    <LoadingProvider>
      <RouterProvider router={router} />
    </LoadingProvider>
  </ThemeProvider>
  // </StrictMode>
);
