import * as React from 'react';
import ReactQuill, { Quill } from 'react-quill';
import ImageUploader from "quill-image-uploader";
import 'react-quill/dist/quill.snow.css';
import { axiosInstance } from '../hooks/request.interceptor';

Quill.register("modules/imageUploader", ImageUploader);

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "imageBlot"
];

const module =
{
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'image': '' }],
    ['clean']                                         // remove formatting button
  ],
  imageUploader: {
    upload: (file,id) => {
      return new Promise((resolve, reject) => {
        const seriesId = localStorage.getItem('seriesId');
        const formData = new FormData();
        formData.append("image", file);
        axiosInstance.post(`/series/admin/uploadImage/${seriesId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(
          res => {
            if (res.data.status) {
              resolve(res.data.url);
            }
          }
        ).catch(err => {
          reject("Upload failed");
          console.error("Error:", err);
        })
      });
    }
  }
}

const QuillEditor = ({ defaultvalue, modifyFunction }) => {
  return (
    <div style={{ 'marginTop': '10px', width: '100%' }}>
      <ReactQuill style={{ width: '100%' }} theme="snow" modules={module} formats={formats} value={defaultvalue} onChange={modifyFunction} />
    </div>
  );
}

export default QuillEditor;