import { useState } from 'react';
import '../css/EditorPage.css'

const EditorPreview = ({ question, options, explanation, answer, topic, section }) => {

    return (
        <div className="preview-wrapper">
            <div className='contents'>
                <p className='heading-wrapper'>Question</p>
                <div dangerouslySetInnerHTML={{ __html: question }}></div>
            </div>
            <div className='contents'>
                <p className='heading-wrapper'>Explanation</p>
                <div dangerouslySetInnerHTML={{ __html: explanation }}></div>
            </div>
            <div className='contents'>
                <p className='heading-wrapper'>Answer</p>
                <div dangerouslySetInnerHTML={{ __html: String.fromCharCode(65 + answer) }}></div>
            </div>
            <div className='contents'>
                <p className='heading-wrapper'>Options</p>
                {
                    options?.map((option, index) => {
                        return (
                            <div>
                                <p className='option' key={index}>
                                {String.fromCharCode(65 + index)}.&nbsp;
                                    <div dangerouslySetInnerHTML={{ __html: option }} />
                                </p>
                            </div>
                        )
                    })
                }
            </div>
            <div className='contents'>
                <p className='heading-wrapper'>Section</p>
                <div dangerouslySetInnerHTML={{ __html: section }}></div>
            </div>
            <div className='contents'>
                <p className='heading-wrapper'>Topic</p>
                <div dangerouslySetInnerHTML={{ __html: topic }}></div>
            </div>
        </div>
    )
}

export default EditorPreview;