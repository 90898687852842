import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  defer,
} from "react-router-dom";
import { LoginPage } from "./pages/Login";
import { HomePage } from "./pages/Home";
import { SeriesPage } from "./pages/Series";
import { TestsPage } from "./pages/Tests";
import { ProtectedLayout } from "./layouts/ProtectedLayout";
// import { HomeLayout } from "./components/HomeLayout";
import "./styles.css";
import { AuthLayout } from "./components/AuthLayout";
// import { useAuth } from "./hooks/useAuth";
import ManageSeries from "./pages/ManageSeries";
import ManageTest from "./pages/ManageTest";
import QuestionsPage from "./pages/Questions";
// import FormDialog from "./components/Dialog";

// ideally this would be an API call to server to get logged in user data

const getToken = () =>
  new Promise((resolve) =>
    setTimeout(() => {
      const token = window.localStorage.getItem("token");
      resolve(token);
    }, 0)
  );

// for error
// const getUserData = () =>
//   new Promise((resolve, reject) =>
//     setTimeout(() => {
//       reject("Error");
//     }, 3000)
//   );
export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={<AuthLayout />}
      loader={() => defer({ userPromise: getToken() })}
    >
      {/* <Route element={<HomeLayout />}> */}
      <Route path="/" element={<LoginPage />} />
      <Route path="/login" element={<LoginPage />} />
      {/* </Route> */}

      {/* <Route path="/dashboard" element={}> */}
      <Route
        path="dashboard"
        element={<ProtectedLayout children={<HomePage />} />}
      />
      <Route
        path="series"
        element={<ProtectedLayout children={<SeriesPage />} />}
      />
      <Route
        path="series/:action/:id?"
        element={<ProtectedLayout children={<ManageSeries />} />}
      />
      <Route
        path="tests/:id"
        element={<ProtectedLayout children={<TestsPage />} />}
      />
      <Route
        path="test/:action/:id"
        element={<ProtectedLayout children={<ManageTest />} />}
      />

      <Route
        path="questions/:id"
        element={<ProtectedLayout children={<QuestionsPage />} />}
      />
      {/* </Route> */}
    </Route>
  )
);
