import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
// import { AppBar } from "./AppBar";
import AppSideBar from "../components/SideBar";

export const ProtectedLayout = ({children}) => {
  const { token } = useAuth();
  // const outlet = useOutlet();
  const navigate = useNavigate();

    if (!token) {
      return <Navigate to="/" />;
    }

  function redirect (){
    navigate("/");
  }

  return (
    <div>
      {/* <AppBar
        pages={[
          { label: "Series", path: "series" },
          { label: "Tests", path: "tests" }
        ]}
      /> */}
      {/* {outlet} */}

        { token === null ? redirect() :
         <div style={{display:'flex'}}>
            <div>
               <AppSideBar/>
            </div>
            <div style={{width:'100%','overflowX':'hidden'}}>
               {children}
            </div>
          </div>
        } 

    </div>
  );
};
