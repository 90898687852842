// import { useAuth } from "./useAuth";
import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: "https://api.swotacademy.co.in/api",
});

axiosInstance.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
