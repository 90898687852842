import {useEffect, useState} from "react";
import {axiosInstance} from "../hooks/request.interceptor";
import Series from "../components/Series";
import '../css/Series.css'
import {useNavigate} from "react-router-dom";
import {useLoading} from '../hooks/useLoading'
import Loader from '../components/Loader'

export const SeriesPage = () => {
  const { loading, setLoading } = useLoading();
  const [series, setSeries] = useState([])
  // const [openModal, setOpenModal] = useState(false)
  const navigate = useNavigate()
  const getAllSeries = () => {
    axiosInstance.get('/series/admin/getAll').then(res => {
      let data = res.data
      console.log("series",data)
      setLoading(false);
      if (data.status) {
        console.log(data.data)
        setSeries(data.data)
      }
    }).catch(err => {
      console.log(err)
    })
  }
  useEffect(() => {
    setLoading(true);
    getAllSeries();
  }, [])

  return (
    (loading ? <Loader /> :
    <div className="series">

      <div className="add-button-wrapper">
        <button className="button-47"
         onClick={
          ()=>
          navigate('/series/add/')
        }
        >Add Series</button>
      </div>

      <div className="series-wrapper">
          {series.map((e,index)=> (
            <Series {...e} key={index} reload={getAllSeries} />
          ))}
      </div>

    </div>)
  );
}
