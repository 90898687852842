import '../css/Loader.css'

const Loader = () => {
	return (
		<div className='loader-wrapper'>
  			<div className="loader"/>
		</div>
	);
}; 
export default Loader;
