import React from 'react';
import '../css/Series.css'
import { IMG_URL } from '../constants'
import { useNavigate } from 'react-router-dom'
import { axiosInstance } from '../hooks/request.interceptor';
import ConfirmDialog from './ConfirmDialog';
import { useState } from 'react';

const Series = ({ name, image, price, isPublished, _id, total_tests, reload }) => {
  const navigate = useNavigate();
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [publishConfirmOpen, setPublishConfirmOpen] = useState(false)
  const openSeries = (_id) => {
    localStorage.setItem('seriesId', _id);
    navigate('/tests/' + _id)
  }
  const editSeries = (_id) => {
    navigate('/series/edit/' + _id)

  }
  const deleteSeries = (_id) => {
    axiosInstance.post('/series/admin/delete/' + _id).then(res => {
      reload();
    })
  }
  const pubishAll = (_id) => {
    axiosInstance.post('/series/admin/seriesTestUpdate/' + _id).then(res => {
      reload();
    })
  }
  return (
    <div className='card-series-box' style={{ 'border': isPublished ? '1px solid green' : '1px solid red' }}>

      <div className='image-wrapper'>
        <img className='image' alt="series" src={IMG_URL + image}>
        </img>
      </div>

      <div className='card-content-wrapper'>
        <div>
          <div className='series-name-wrapper'>
            <p className='name'>{name}</p>
          </div>

          <div className='total-tests-wrapper'>
            <p>Total tests : {total_tests}</p>
          </div>

          <div className='price-wrapper'>
            <p>Price : ₹{price}</p>
          </div>

          <div className='view-button-wrapper'>
            <button className='view-button custom-btn btn-6' onClick={() => { openSeries(_id) }}>
              VIEW
            </button>
          </div>

          <div className='buttons'>
            <button className='custom-btn btn-6' onClick={() => { editSeries(_id) }}>EDIT</button>
            <ConfirmDialog
              title="Delete Series?"
              open={confirmOpen}
              setOpen={setConfirmOpen}
              onConfirm={() => { deleteSeries(_id) }}
            >
              Are you sure you want to delete this Series?
            </ConfirmDialog>
            <button className='custom-btn btn-6' onClick={() => { setConfirmOpen(true) }}
            >DELETE</button>
          </div>
        </div>
        <div className='view-button-wrapper'>
          <ConfirmDialog
            title="Delete Series?"
            open={publishConfirmOpen}
            setOpen={setPublishConfirmOpen}
            onConfirm={() => { pubishAll(_id) }}
          >
            Are you sure you want to Publish this series and all its tests with is not published yet ?
          </ConfirmDialog>
          <button className='publish-button view-button custom-btn btn-6' onClick={() => { setPublishConfirmOpen(true) }}>
            Publish All
          </button>
        </div>

      </div>
    </div>
  )
}

export default Series