import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { axiosInstance } from "../hooks/request.interceptor";
import Test from "../components/Test";
import '../css/Series.css'
import Loader from "../components/Loader";
import { useLoading } from "../hooks/useLoading";

export const TestsPage = () => {
  const [tests, setTests] = useState([]);
  const { loading, setLoading } = useLoading();
  const { id } = useParams()
  const navigate = useNavigate()

  const getTests = () => {
    axiosInstance.get('/tests/admin/get/' + id).then(res => {
      setTests(res.data.data)
      setLoading(false);
      console.log("series id",id);
      console.log("tests", res.data)
    })
  }

  useEffect(() => {
    setLoading(true);
    getTests();
  }, []);

  return (
    (loading ? <Loader /> :
      <div className="series">
        <h2 style={{ 'fontFamily': 'sans-serif', 'fontSize': '35px' }}>Tests</h2>
        <div className="add-button-wrapper">
          <button className="button-47"
            onClick={
              () =>
                navigate('/test/add/' + id)
            }
          >Add Test</button>
        </div>

        <div className="series-wrapper">
          {tests.map((e, index) => (
            <Test {...e} reload={getTests} key={index} />
          ))}
        </div>
      </div>
    ));
};
